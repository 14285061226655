export const hero = {
  title: "Reservation & waitlist best practices",
  description:
    "Everything you need to know about SpotOn Reserve to manage your seating and get the most out of your tables.",
  imageName: "reservations-hero.png",
  footer: [
    "Reserve",
    "Restaurant",
    "Reserve",
    "Restaurant",
    "Reserve",
    "Restaurant",
    "Reserve",
    "Restaurant",
    "Reserve",
    "Restaurant",
    "Reserve",
    "Restaurant",
    "Reserve",
    "Restaurant",
    "Reserve",
    "Restaurant",
    "Reserve",
    "Restaurant",
    "Reserve",
    "Restaurant",
    "Reserve",
    "Restaurant",
    "Reserve",
    "Restaurant",
    "Reserve",
    "Restaurant",
  ],
};

export const howItWorks = {
  title: `Why SpotOn Reserve`,
  descriptions: [
    `Many people don't want to eat at a restaurant if they can't make a reservation online or have to wait longer than 30 minutes for a table. Additionally, when front-of-house systems are inefficient, staff often project their frustrations onto guests, making an unpleasant situation for everyone. That's where SpotOn Reserve can help.`,
    `SpotOn Reserve is a table-management system that brings together your reservations, waitlist, and the SpotOn Restaurant POS. It enables guests to book tables or add themselves to the waitlist online. Then, from a single dashboard, hosts can manage all incoming parties, add walk-ins to the waitlist, see where seated guests are at with their meals, send texts when tables are ready, and quote accurate wait times to new arrivals.`,
    `SpotOn Reserve makes everyone's experience better. With it, you can:`,
  ],
};

export const steps = [
  {
    step: 1,
    title: "Make booking easy with online reservations",
  },
  {
    step: 2,
    title: "Maximize your guest count with a digital waitlist",
  },
  {
    step: 3,
    title: "Collect guest information for marketing campaigns",
  },
];

export const largeFeatures = {
  sectionTitle: "3 best practices for success with SpotOn Reserve",
  featureBlocks: [
    {
      blockTitle: "Launch online reservations",
      blockImg: "reserve-wk-large-1.png",
      blockSubTitle:
        "Encourage guests to make reservations directly from you online and eliminate the per-cover fees that third-party reservation platforms charge every time someone books a table.",
      forceReverse: false,
      blockList: [
        `Clearly set your reservation parameters from the get-go so guests can book online without having to call your restaurant`,
        `Give guests multiple ways to book a table—through your website and Google Search and Maps`,
        `Reduce no-shows and last-minute cancellations with 2-way texting that allows guests to confirm or cancel ahead of time`,
      ],
    },
    {
      blockTitle: "Set up your digital waitlist",
      blockImg: "reserve-wk-large-2.png",
      blockSubTitle:
        "Make your front-of-house run like clockwork with a waitlist system that makes seating effortless for even the busiest of restaurants.",
      blockList: [
        `Minimize peak-hour rushes with spread pacing that recommends off-time seating and helps your kitchen run smoothly`,
        `Get more business by allowing guests to add themselves to your waitlist from your website and Google`,
        `Maximize your tables and seat guests faster with automatic table suggestions for hosts`,
        `Assure guests of their place in line with a real-time view of their spot on the waitlist`,
        `Keep waiting parties in the know with automatic and accurate wait times and two-way texting`,
      ],
      forceReverse: false,
    },
    {
      blockTitle: "Enhance your guest experience",
      blockImg: "reserve-wk-large-3.png",
      blockSubTitle:
        "Get a better understanding of your guests' habits and particular preferences to make their dining experience even better.",
      blockList: [
        `Encourage feedback by checking on guests with mid-meal texts for a better guest experience and positive reviews*`,
        `Gain insights into the average length of your guest stay and adjust your meal duration times for more accurate wait times `,
        `Give guests a consistent brand experience with personalized and customized text and paging confirmations`,
        `Add every walk-in's information to Reserve so you can build your guest database and stay connected with your regulars`,
      ],
      forceReverse: false,
      footer: "*Standard text message rates apply.",
    },
  ],
};

export const quotesSlider = [
  {
    subtext: `“Our average Friday and Saturday sales used to be $9k - $10k. With SpotOn Reserve, we take in $12k, $13k, $14k and run more smoothly because there’s no longer a big crash in the kitchen.”`,
    imageName: "11-bay-table-logo.png",
    imageTitle: "Mike Harrell",
    imageSubtitle: "Owner, 11th and Bay Southern Table",
  },
  {
    subtext: `"With SpotOn Reserve, I can tie reservations with the POS and that's critical for any restaurateur who's looking at pricing, variables, and cost per acquisition models.”`,
    imageName: "china-live-logo.png",
    imageTitle: "George Chen",
    imageSubtitle: "Founder, China Live",
  },
  {
    subtext: `“Whether it's having all of the applications integrated into one system or being able to have a direct line to their support staff, SpotOn is the only tech partner that we need to run our growing enterprise."`,
    imageName: "lotus-logo.png",
    imageTitle: "Brad Manske",
    imageSubtitle: "Vice President, Lotus Concept Management\n",
  },
];

export const productLinks = {
  title: "Connect with the entire SpotOn ecosystem",
  products: [
    {
      title: `<a class="text-[24px] underline hover:underline leading-[31px] mb-8" href="/restaurant-pos/" target="_blank">SpotOn Restaurant POS</a>`,
      bullets: [
        `Enable hosts to see where tables are at in their meal with flags that show statuses like drinks, appetizer, main course, dessert, or a paid check`,
        `Elevate your guest experience further by transferring notes, special requests, and food allergies to the attending server`,
      ],
    },
    {
      title: `<a class="text-[24px] underline hover:underline leading-[31px] mb-8" href="/products/marketing/" target="_blank">SpotOn Marketing</a> & <a class="text-[24px] underline hover:underline leading-[31px] mb-8" href="/products/loyalty/" target="_blank">Loyalty</a>`,
      bullets: [
        `Seamlessly transfer guest data from Reserve to SpotOn Marketing so you can send email promotions to guests and stay at the top of their minds`,
        `Integrate SpotOn Loyalty with the Reserve Host app and identify your loyalty members before they even sit down`,
      ],
    },
    {
      title: `<a class="text-[24px] underline hover:underline leading-[31px] mb-8" href="/products/online-order-and-delivery/" target="_blank">SpotOn Order & QR</a>`,
      bullets: [
        `Create a fulfillment system with SpotOn Order that texts customers when their take-out orders are ready`,
        `Send seated guests a text linking to your online menu so they can see your menu items and order drinks or starters before a server arrives`,
      ],
    },
    {
      title: `<a class="text-[24px] underline hover:underline leading-[31px] mb-8" href="/products/website/" target="_blank">SpotOn Website</a>`,
      bullets: [
        `Let our experts build and maintain a customized website for you that's always up-to-date and on-brand with your restaurant`,
        `Connect your reservations and waitlist to match your brand and give guests a consistent experience`,
      ],
    },
  ],
};

export const footer = {
  title: "Don't have SpotOn Reserve yet",
  subtext:
    "Talk to one of our restaurant technology pros and see how easy it is to get started with reservations and waitlisting to streamline your entire front-of-house operation. ",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/lp/reservations/demo",
  },
};
