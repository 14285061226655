import React, { useState } from "react";
import { motion } from "framer-motion";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import Image from "../../../components/ImageQuerys/RandomImages";
import GlobalImage from "../../../components/image";
import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
import {
  hero,
  howItWorks,
  steps,
  largeFeatures,
  quotesSlider,
  productLinks,
  footer,
} from "../../../data/lp/reserve-best-practices";
import heroBg from "../../../images/svg/vector-18.svg";
import heroBgLeft from "../../../images/svg/vector-16.svg";
import quotesBackground from "../../../images/svg/quotes-blue.svg";
import doubleCircleBig from "../../../images/svg/double-circle-top-right-big.svg";
import doubleCircleSmall from "../../../images/svg/double-circle-top-right-small.svg";
import quotes from "../../../images/svg/quotes.svg";
import check from "../../../images/svg/checkmark.svg";
import AppetizeStyles from "../../../styles/lp-styles/appetize-lp-styles";
import FooterHero from "../../../components-v2/Sections/Hero/FooterHero";

import "../../../css/products-qr.css";

const quotesSection = [
  <motion.div
    key={quotesSlider[0].imageSubtitle}
    className="quotes__item"
    initial={{ opacity: 0, x: "-100px" }}
    animate={{ opacity: 1, x: 0 }}
  >
    <p className="quotes__item--subtext">{quotesSlider[0].subtext}</p>
    <GlobalImage
      className="quotes__item--image"
      imageName={quotesSlider[0].imageName}
    />
    <div className="quotes__item--title">{quotesSlider[0].imageTitle}</div>
    <div className="quotes__item--sub-title">
      {quotesSlider[0].imageSubtitle}
    </div>
  </motion.div>,
  <motion.div
    key={quotesSlider[1].imageSubtitle}
    className="quotes__item"
    initial={{ opacity: 0, x: "100px" }}
    animate={{ opacity: 1, x: 0 }}
  >
    <p className="quotes__item--subtext">{quotesSlider[1].subtext}</p>
    <GlobalImage
      className="quotes__item--image"
      imageName={quotesSlider[1].imageName}
    />
    <div className="quotes__item--title">{quotesSlider[1].imageTitle}</div>
    <div className="quotes__item--sub-title">
      {quotesSlider[1].imageSubtitle}
    </div>
  </motion.div>,
  <motion.div
    key={quotesSlider[2].imageSubtitle}
    className="quotes__item"
    initial={{ opacity: 0, x: "100px" }}
    animate={{ opacity: 1, x: 0 }}
  >
    <p className="quotes__item--subtext">{quotesSlider[2].subtext}</p>
    <GlobalImage
      className="quotes__item--image"
      imageName={quotesSlider[2].imageName}
    />
    <div className="quotes__item--title">{quotesSlider[2].imageTitle}</div>
    <div className="quotes__item--sub-title">
      {quotesSlider[2].imageSubtitle}
    </div>
  </motion.div>,
];

const ReserveBestPractices = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <Layout
      transparentHeader
      isLanding
      useNewLPHeader
      className="non-fixed-width qr-page"
      phoneNumber="+18882246506"
      phoneText="(888) 224-6506"
    >
      <SEO
        title="Restaurant Waitlist and Reservations Best Practices | SpotOn Reserve"
        description="Learn how to use your restaurant waitlist and reservations solution to seat guests faster, make the most of your table space, and take charge of your front-of-house."
      />

      <section className="pt-40 bg-black w-full relative">
        <img
          alt="background"
          src={heroBgLeft}
          className="absolute hidden xl:block left-0 top-24 opacity-0 lg:opacity-100"
        />
        <img
          alt="background"
          src={heroBg}
          className="absolute -right-48 md:right-0 bottom-6"
        />
        <div
          className={`grid grid-cols-1 md:grid-cols-2 relative 
         px-4 md:px-10 lg:px-20 xl:px-40 max-w-1440 mx-auto 
         gap-x-10 2xl:gap-x-48 gap-y-16 
         `}
        >
          <div className="flex flex-col justify-center w-full">
            <Heading
              className="text-white"
              level={1}
              injectionType={1}
              withBlueSymbol
            >
              {hero.title}
            </Heading>

            <div className="text-black-500 text-p-large">
              {hero.description}
            </div>
          </div>

          <div className="w-full flex flex-col justify-center">
            <Image className="w-full h-auto" imageName={hero.imageName} />
          </div>
        </div>

        <div
          className="relative bg-black w-full py-7 mt-20 lg:mt-40"
          style={{ borderTop: "1px solid #fff" }}
        >
          {hero.footer.map((item, idx) => (
            <span
              key={(idx + 1).toString()}
              className="text-white text-[14px] px-5 md:px-10 font-thin"
            >
              {item}
            </span>
          ))}
        </div>
      </section>

      <section
        className={`mt-28
        grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-10
        px-4 md:px-10 lg:px-20 xl:px-40 max-w-1440 mx-auto
      `}
      >
        <div>
          <Heading
            level={2}
            injectionType={2}
            className="lg:max-w-[370px]"
            withBlueSymbol
            symbol="?"
          >
            {howItWorks.title}
          </Heading>
        </div>

        <div>
          {howItWorks.descriptions.map((text, idx) => (
            <p key={idx.toString()} className="text-black-200 text-p">
              {text}
            </p>
          ))}
        </div>
      </section>

      <section
        className={`mt-8 md:mt-24 w-full
        grid grid-cols-1 md:grid-cols-3 gap-y-6 gap-x-10
        px-4 md:px-10 lg:px-20 xl:px-40 max-w-1440 mx-auto
        steps-section
      `}
      >
        <div className="md:min-h-[320px] pl-6 md:pl-10 qr-step-1 py-4 md:py-0 step step-1">
          <div className="text-h1 font-bold text-black">{steps[0].step}</div>
          <div className="text-p text-black-200 max-w-[248px]">
            {steps[0].title}
          </div>
        </div>

        <div className="md:min-h-[320px] pl-6 md:pl-10 qr-step-2 py-4 md:pt-28 step step-2">
          <div className="text-h1 font-bold text-black">{steps[1].step}</div>
          <div className="text-p text-black-200 max-w-[248px]">
            {steps[1].title}
          </div>
        </div>

        <div className="md:min-h-[320px] pl-6 md:pl-10 qr-step-3 py-4 md:py-0 step step-3">
          <div className="text-h1 font-bold text-black">{steps[2].step}</div>
          <div className="text-p text-black-200 max-w-[248px]">
            {steps[2].title}
          </div>
        </div>
      </section>

      <section className="mt-10 lg:mt-28 max-w-[1122px] w-full mx-auto]">
        <GlobalImage
          imageName="reserve-second-hero.png"
          className="w-full min-h-[200px]"
        />
      </section>

      <LargeFeatures className="mt-24 lg:mt-48" sectionData={largeFeatures} />

      <section className="max-w-1440 w-full lg:px-10">
        <div className="quotes">
          <img
            className="quotes__quotes-bg"
            src={quotesBackground}
            alt="quotes background"
          />

          <img
            className="quotes__circle-bg-big"
            src={doubleCircleBig}
            alt="circles"
          />
          <img
            className="quotes__circle-bg-small"
            src={doubleCircleSmall}
            alt="circles"
          />

          <img className="quotes__transparent" src={quotes} alt="quotes" />
          <div className="quotes__items-wrapper">
            {quotesSection[slideIndex]}
          </div>

          <div className="quotes__buttons justify-center">
            <button
              type="button"
              className={`quotes__slide-button ${
                slideIndex === 0 ? "active" : ""
              }`}
              onClick={() => setSlideIndex(0)}
            >
              <div className="outer">
                <div className="inner" />
              </div>
            </button>

            <button
              type="button"
              className={`quotes__slide-button ${
                slideIndex === 1 ? "active" : ""
              }`}
              onClick={() => setSlideIndex(1)}
            >
              <div className="outer">
                <div className="inner" />
              </div>
            </button>

            <button
              type="button"
              className={`quotes__slide-button ${
                slideIndex === 2 ? "active" : ""
              }`}
              onClick={() => setSlideIndex(2)}
            >
              <div className="outer">
                <div className="inner" />
              </div>
            </button>
          </div>
        </div>
      </section>

      <section className="px-4 md:px-10 w-full mt-20 lg:mt-36">
        <Heading
          level={2}
          injectionType={2}
          withBlueSymbol
          className="mx-auto text-black text-center"
        >
          {productLinks.title}
        </Heading>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 max-w-[1122px] mx-auto mt-20 mb-40">
          {productLinks.products.map((product) => (
            <div key={product.title}>
              <div
                className="text-[24px]"
                dangerouslySetInnerHTML={{ __html: product.title }}
              />

              <ul className="pl-0 list-none flex flex-col gap-y-8 mt-8">
                {product.bullets.map((bullet) => (
                  <li
                    key={bullet}
                    className="text-[18px] flex items-center max-w-[450px] font-bold"
                  >
                    <img alt="checkbox" src={check} className="mr-6" />
                    <div>{bullet}</div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>

      {/* <FooterHero */}
      {/*  className="mt-20 lg:mt-36 mb-12 lg:mb-20" */}
      {/*  {...footer} */}
      {/*  variant={2} */}
      {/*  titleBlueSymbol="?" */}
      {/* /> */}

      <AppetizeStyles />
    </Layout>
  );
};

export default ReserveBestPractices;
